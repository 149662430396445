import { Exchange, ExchangeId } from '@robotrader/common-types';
import { Bloc, DataError } from '@robotrader/core-lib';

import { AuthBloc } from '@/modules/auth';
import {
  GetAllExchangesUseCase,
  GetAllOperationsUseCase,
  GetTraderRunningStateUseCase,
  SetTraderStateUseCase,
  traderInitialState,
  TraderState,
} from '@/modules/trader/app';
import { Operation } from '@/modules/trader/domain';

interface TraderBlocProps {
  getAllOperationsUseCase: GetAllOperationsUseCase;
  getAllExchangesUseCase: GetAllExchangesUseCase;
  setTraderStateUseCase: SetTraderStateUseCase;
  getTraderRunningStateUseCase: GetTraderRunningStateUseCase;
  authBloc: AuthBloc;
}

export class TraderBloc extends Bloc<TraderState> {
  private getAllOperationsUseCase: GetAllOperationsUseCase;
  private getAllExchangesUseCase: GetAllExchangesUseCase;
  private setTraderStateUseCase: SetTraderStateUseCase;
  private getTraderRunningStateUseCase: GetTraderRunningStateUseCase;
  private authBloc: AuthBloc;

  constructor({
    getAllOperationsUseCase,
    getAllExchangesUseCase,
    getTraderRunningStateUseCase,
    setTraderStateUseCase,
    authBloc,
  }: TraderBlocProps) {
    super(traderInitialState);

    this.getAllOperationsUseCase = getAllOperationsUseCase;
    this.getAllExchangesUseCase = getAllExchangesUseCase;
    this.setTraderStateUseCase = setTraderStateUseCase;
    this.getTraderRunningStateUseCase = getTraderRunningStateUseCase;
    this.authBloc = authBloc;

    this.init();
  }

  private init() {
    if (this.authBloc.state.kind === 'LoadedAuthState') {
      this.loadOperations();
      this.loadTradingState();
    }

    this.authBloc.subscribe((authState) => {
      if (authState.kind === 'LoadedAuthState') {
        this.loadOperations();
        this.loadTradingState();
      } else if (authState.kind === 'NotLoggedAuthState') {
        this.changeState(traderInitialState);
      }
    });
  }

  async loadTradingState() {
    const result = await this.getTraderRunningStateUseCase.execute();

    result.fold(
      (error: DataError) => this.changeState(this.handleError(error)),
      (isRunning: boolean) => {
        this.changeState({
          ...this.state,
          running: isRunning,
        });
      },
    );
  }

  async loadOperations(exchangeId?: ExchangeId) {
    const result = await this.getAllOperationsUseCase.execute(exchangeId);

    result.fold(
      (error: DataError) => this.changeState(this.handleError(error)),
      (operations: Operation[]) => {
        this.changeState({
          kind: 'LoadedTraderState',
          running: this.state.running,
          operations,
          accum: operations[0]?.accum || 0,
        });
      },
    );
  }

  async getExchanges(): Promise<Exchange[]> {
    const result = await this.getAllExchangesUseCase.execute();

    return result.getOrElse([]);
  }

  async startTrader() {
    return this.changeTradingState(true);
  }

  async stopTrader() {
    return this.changeTradingState(false);
  }

  private async changeTradingState(isTrading: boolean) {
    const result = await this.setTraderStateUseCase.execute({
      isTrading,
    });

    result.fold(
      (error: DataError) => this.changeState(this.handleError(error)),
      () => {},
    );
  }

  private handleError(error: DataError): TraderState {
    const ERROR: TraderState = {
      running: this.state.running,
      kind: 'ErrorTraderState',
      error: 'Sorry, an error has ocurred. Please try again later',
    };

    switch (error.kind) {
      case 'ApiError':
        break;
      case 'UnexpectedError':
        break;
      case 'Unauthorized':
        break;
      case 'NotFound':
        break;
      default:
        break;
    }

    return ERROR;
  }
}
