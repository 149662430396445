import { ExchangeId } from '@robotrader/common-types';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import { HTTPService } from '@/modules/shared';
import { Operation, OperationRepository } from '@/modules/trader/domain';

import { GetOperationsResponse } from './GetOperationsResponse';
import { OperationMapper } from './OperationMapper';

interface OperationHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class OperationHTTPRepository implements OperationRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: OperationHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  async getAll(
    exchangeId?: ExchangeId,
  ): Promise<Either<DataError, Operation[]>> {
    try {
      const {
        data: { data: operationsDto },
      } = await this.http.get<GetOperationsResponse>(
        `${this.config.apiUrl}/client/operation`,
      );

      let filteredOperations = operationsDto;

      if (exchangeId) {
        filteredOperations = operationsDto.filter(
          (op) => op.exchange.id === exchangeId,
        );
      }

      return Either.right(
        OperationMapper.operationDtoArrayToOperationArray(
          filteredOperations,
          true,
        ),
      );
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }
}
