import { useState } from 'react';

export function useTabs<T>(tabs: Array<T>, defaultTab?: T) {
  const [currentTab, setCurrentTab] = useState<T>(defaultTab || tabs[0]);

  function checkCurrentTabIs(tab: T) {
    return currentTab === tab;
  }

  return { currentTab, setCurrentTab, checkCurrentTabIs, tabs };
}
