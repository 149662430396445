import { Container, Flex, Footer, Header, Main } from '@robotrader/design-system';
import { Outlet } from 'react-router-dom';

import Colors from '@/app/styles/Colors';
import { NavBar } from '@/app/ui/organisms';

import styles from './MainLayout.module.scss';

const MainLayout = () => (
  <Flex.Container
    flexDirection="column"
    className={styles.wrapper}
    style={{ backgroundColor: Colors.black, color: Colors.white }}
  >
    <Header style={{ backgroundColor: Colors.notSoBlack }}>
      <Container>
        <NavBar />
      </Container>
    </Header>
    <Main>
      <Container style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column' }}>
        <Outlet />
      </Container>
    </Main>
    <Footer />
  </Flex.Container>
);

export default MainLayout;
