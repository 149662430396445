import { DataError, Either, UseCase } from '@robotrader/core-lib';

import {
  AddExchangeAccessProps,
  ProfileRepository,
} from '@/modules/profile/domain';
import { ExchangeAccess } from '@/modules/shared';

interface AddExchangeAccessesUseCaseProps {
  profileRepository: ProfileRepository;
}

export class AddExchangeAccessesUseCase
  implements
    UseCase<AddExchangeAccessProps, Either<DataError, ExchangeAccess[]>>
{
  private profileRepository: ProfileRepository;

  constructor({ profileRepository }: AddExchangeAccessesUseCaseProps) {
    this.profileRepository = profileRepository;
  }

  execute(
    exchangeAccess: AddExchangeAccessProps,
  ): Promise<Either<DataError, ExchangeAccess[]>> {
    return this.profileRepository.addExchangeAccess(exchangeAccess);
  }
}
