import { Profile } from '@/modules/profile/domain';
import { ExchangeAccess } from '@/modules/shared';

export interface LoadingProfileState {
  kind: 'LoadingProfileState';
}

export interface LoadedProfileState {
  kind: 'LoadedProfileState';
  profile: Profile;
  exchangeAccesses?: ExchangeAccess[];
}

export interface UpdatedProfileState {
  kind: 'UpdatedProfileState';
  profile: Profile;
  exchangeAccesses?: ExchangeAccess[];
}

export interface ErrorProfileState {
  kind: 'ErrorProfileState';
  profile?: Profile;
  exchangeAccesses?: ExchangeAccess[];
  error: string;
}

export type ProfileState =
  | LoadingProfileState
  | LoadedProfileState
  | UpdatedProfileState
  | ErrorProfileState;

export const profileInitialState: ProfileState = {
  kind: 'LoadingProfileState',
};
