const Colors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  blue: '#4179F1',
  blueDarker: '#2d52a0',
  purple: '#7D29EF',
  purpleDarker: '#693194',
  green: '#71faa2',
  greenDarker: '#62b573',
  red: '#E50045',
  redDarker: '#a10232',
  black: '#000000',
  notSoBlack: '#282828',
  greyDarker: '#3E3E3E',
  grey: '#6C6C6C',
  grey2: '#464646',
  grey3: '#CBCBCB',
  grey4: '#3A3A3A',
  grey5: '#E0E0E0',
  yellow1: '#d7bd69',
  yellow2: '#ffeeba',
  yellow3: '#654c01',
  yellow: '',
  yellowDarker: '',
  primary: '',
  primaryDarker: '',
  secondary: '',
  secondaryDarker: '',
};

Colors.primary = Colors.blue;
Colors.primaryDarker = Colors.blueDarker;
Colors.secondary = Colors.green;
Colors.secondaryDarker = Colors.greenDarker;
Colors.yellow = Colors.yellow1;
Colors.yellowDarker = Colors.yellow3;

export default Colors;
