import {
  AuthId,
  Email,
  Method,
  Name,
  ProfileI,
  ProfileId,
  Surname,
} from '@robotrader/common-types';

import { ExchangeAccess } from '@/modules/shared';

export class Profile {
  id: ProfileId;
  userId: AuthId;
  name: Name;
  surname: Surname;
  email: Email;
  exchangeAccesses?: ExchangeAccess[];
  isTrading: boolean;
  method: Method | null;
  leverage: number;

  private constructor(props: ProfileI) {
    this.id = props.id;
    this.userId = props.userId;
    this.name = props.name;
    this.surname = props.surname;
    this.email = props.email;
    this.exchangeAccesses = props.exchangeAccesses;
    this.isTrading = props.isTrading;
    this.method = props.method;
    this.leverage = props.leverage;
  }

  static create(props: ProfileI): Profile {
    return new Profile(props);
  }
}
