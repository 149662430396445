import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { ProfileRepository } from '@/modules/profile/domain';

interface GetTraderRunningStateUseCaseProps {
  profileRepository: ProfileRepository;
}

export class GetTraderRunningStateUseCase
  implements UseCase<void, Either<DataError, boolean>>
{
  private profileRepository: ProfileRepository;

  constructor({ profileRepository }: GetTraderRunningStateUseCaseProps) {
    this.profileRepository = profileRepository;
  }

  async execute(): Promise<Either<DataError, boolean>> {
    return this.profileRepository.isTrading();
  }
}
