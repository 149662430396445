import { Block, Button, ExchangeIcon, Flex, Icon, LabelValue } from '@robotrader/design-system';
import styled from 'styled-components/macro';

import Colors from '@/app/styles/Colors';
import { ExchangeAccess } from '@/modules/shared';

interface ExchangeListProps {
  exchanges: ExchangeAccess[];
  className?: string;
  style?: React.CSSProperties;
  removeExchange: (exchangeAccess: ExchangeAccess) => void;
}

const ExchangeList = (props: ExchangeListProps) => {
  const { exchanges, className, style, removeExchange } = props;

  return (
    <Block className={className} style={style}>
      {exchanges.map((exchangeAccess) => (
        <Flex.Container
          key={exchangeAccess.id}
          alignItems="center"
          style={{
            backgroundColor: Colors.notSoBlack,
            padding: '1.5rem',
            borderRadius: '6px',
            gap: '2rem',
            marginBottom: '1rem',
          }}
        >
          <ExchangeIcon exchangeName={exchangeAccess.exchangeName} />
          <LabelValue label="Name" value={exchangeAccess.name} />
          <LabelValue label="API Key" value={exchangeAccess.apiKey} />
          <Button $size="sm" style={{ marginLeft: 'auto' }} $variant="cancel">
            <Icon.Trash
              width={16}
              onClick={() => {
                removeExchange(exchangeAccess);
              }}
            />
          </Button>
        </Flex.Container>
      ))}
    </Block>
  );
};

export default styled(ExchangeList)``;
