import {
  Action,
  ClosingOperationI,
  Direction,
  Exchange,
  ExchangeOrderId,
  OperationId,
  OrderSide,
  OrderStatus,
  OrderType,
  Pair,
} from '@robotrader/common-types';
import { assertNever, dayjs } from '@robotrader/common-utils';

import { User } from '@/modules/user/domain';

import { TradingSignal } from './TradingSignal';

interface OperationProps {
  id: OperationId;
  exchangeOrderId: ExchangeOrderId;
  user: User;
  tradingSignal?: TradingSignal;
  exchange: Exchange;
  action: Action;
  pair: Pair;
  side: OrderSide;
  type: OrderType;
  status: OrderStatus;
  accum: number;
  quantity: number;
  price: number;
  leverage: number;
  createdAt: Date;
  outcome?: number;
  fee: number;
  closingOperation?: ClosingOperationI;
}

export class Operation {
  id: OperationId;
  exchangeOrderId: ExchangeOrderId;
  user: User;
  tradingSignal?: TradingSignal;
  action: Action;
  exchange: Exchange;
  pair: Pair;
  side: OrderSide;
  type: OrderType;
  status: OrderStatus;
  accum: number;
  createdAt: Date;
  quantity: number;
  price: number;
  leverage: number;
  _outcome?: number;
  _fee: number;
  closingOperation?: ClosingOperationI;

  private constructor(props: OperationProps) {
    this.id = props.id;
    this.exchangeOrderId = props.exchangeOrderId;
    this.user = props.user;
    this.action = props.action;
    this.exchange = props.exchange;
    this.pair = props.pair;
    this.tradingSignal = props.tradingSignal;
    this.side = props.side;
    this.type = props.type;
    this.status = props.status;
    this.accum = props.accum;
    this.quantity = props.quantity;
    this.price = props.price;
    this.leverage = props.leverage;
    this.createdAt = props.createdAt;
    // eslint-disable-next-line no-underscore-dangle
    this._outcome = props.outcome;
    // eslint-disable-next-line no-underscore-dangle
    this._fee = props.fee;

    if (props.closingOperation) {
      this.closingOperation = props.closingOperation;
    }
  }

  static create(props: OperationProps): Operation {
    return new Operation(props);
  }

  get outcome(): number | undefined {
    return this.closingOperation === undefined
      ? // eslint-disable-next-line no-underscore-dangle
        this._outcome
      : this.closingOperation.outcome;
  }

  get outcomePercent(): number | undefined {
    if (this.closingOperation === undefined) return undefined;

    const { closingOperation, price, quantity, leverage } = this;
    const { outcome } = closingOperation;

    const prevAmount = price * quantity;
    const oPercent = (outcome / prevAmount) * leverage;

    return oPercent * 100;
  }

  get fee(): number {
    // // eslint-disable-next-line no-underscore-dangle
    // if (this.closingOperation === undefined) return this._fee;

    // // eslint-disable-next-line no-underscore-dangle
    // return this._fee + this.closingOperation.fee;
    // eslint-disable-next-line no-underscore-dangle
    return this._fee;
  }

  get amount(): number {
    return this.quantity * this.price;
  }

  get createdAtTime() {
    return dayjs(this.createdAt).format('DD-MM-YYYY HH:mm:ss');
  }

  get timestamp() {
    return dayjs(this.createdAt).valueOf();
  }

  get direction(): Direction | undefined {
    const { action, side, tradingSignal } = this;

    if (tradingSignal) {
      return tradingSignal.direction;
    }

    if (action === Action.ENTRY) {
      if (side === OrderSide.SELL) {
        return Direction.SHORT;
      }

      if (side === OrderSide.BUY) {
        return Direction.LONG;
      }
    }

    if (action === Action.EXIT) {
      if (side === OrderSide.SELL) {
        return Direction.LONG;
      }

      if (side === OrderSide.BUY) {
        return Direction.SHORT;
      }
    }

    return assertNever({ action, side } as never);
  }

  get isOpen(): boolean {
    return this.action === Action.ENTRY && this.closingOperation === undefined;
  }
}
