import { Exchange } from '@robotrader/common-types';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import { HTTPService } from '@/modules/shared';
import { ExchangeRepository } from '@/modules/trader/domain';

import { ExchangeMapper } from './ExchangeMapper';
import { GetExchangesResponse } from './GetExchangesResponse';

interface ExchangeHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class ExchangeHTTPRepository implements ExchangeRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: ExchangeHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  async getAll(): Promise<Either<DataError, Exchange[]>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const {
        data: { data: exchangesDto },
      } = await this.http.get<GetExchangesResponse>(
        `${this.config.apiUrl}/exchanges`,
      );

      return Either.right(
        ExchangeMapper.exchangeDtoArrayToExchangeArray(exchangesDto),
      );
    } catch (error) {
      return Either.left({ kind: 'UnexpectedError', message: error as Error });
    }
  }
}
