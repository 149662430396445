import { ExchangeAccessId } from '@robotrader/common-types';
import { Bloc, DataError } from '@robotrader/core-lib';

import { AuthBloc } from '@/modules/auth';
import {
  AddExchangeAccessesUseCase,
  GetProfileUseCase,
  profileInitialState,
  ProfileState,
  RemoveExchangeAccessesUseCase,
} from '@/modules/profile/app';
import { Profile } from '@/modules/profile/domain';
import { ExchangeAccess } from '@/modules/shared';

interface ProfileBlocProps {
  getProfileUseCase: GetProfileUseCase;
  addExchangeAccessesUseCase: AddExchangeAccessesUseCase;
  removeExchangeAccessesUseCase: RemoveExchangeAccessesUseCase;
  authBloc: AuthBloc;
}

export class ProfileBloc extends Bloc<ProfileState> {
  private getProfileUseCase: GetProfileUseCase;
  private addExchangeAccessesUseCase: AddExchangeAccessesUseCase;
  private removeExchangeAccessesUseCase: RemoveExchangeAccessesUseCase;
  private authBloc: AuthBloc;

  constructor({
    getProfileUseCase,
    addExchangeAccessesUseCase,
    removeExchangeAccessesUseCase,
    authBloc,
  }: ProfileBlocProps) {
    super(profileInitialState);

    this.getProfileUseCase = getProfileUseCase;
    this.addExchangeAccessesUseCase = addExchangeAccessesUseCase;
    this.removeExchangeAccessesUseCase = removeExchangeAccessesUseCase;
    this.authBloc = authBloc;

    this.init();
  }

  private init() {
    this.loadProfile();

    this.authBloc.subscribe((authState) => {
      if (authState.kind === 'LoadedAuthState') {
        this.loadProfile();
      } else if (authState.kind === 'NotLoggedAuthState') {
        this.changeState(profileInitialState);
      }
    });
  }

  async loadProfile() {
    if (this.authBloc.state.kind !== 'LoadedAuthState') {
      return;
    }

    const profileResult = await this.getProfileUseCase.execute();

    let profile: Profile;
    try {
      profile = profileResult.getOrThrow();

      this.changeState({
        kind: 'LoadedProfileState',
        profile,
        exchangeAccesses: profile.exchangeAccesses,
      });
    } catch (error) {
      this.changeState(ProfileBloc.handleError(error as any));
    }
  }

  async addExchangeAccess(exchangeAccess: Omit<ExchangeAccess, 'profileId'>) {
    if (this.state.kind !== 'LoadedProfileState') return;

    const result = await this.addExchangeAccessesUseCase.execute(
      exchangeAccess,
    );

    const exchangeAccesses = result.getOrThrow();

    this.changeState({
      ...this.state,
      exchangeAccesses,
    });
  }

  async removeExchangeAccess(exchangeAccessId: ExchangeAccessId) {
    if (this.state.kind !== 'LoadedProfileState') return;

    const result = await this.removeExchangeAccessesUseCase.execute(
      exchangeAccessId,
    );

    const exchangeAccesses = result.getOrThrow();

    this.changeState({
      ...this.state,
      exchangeAccesses,
    });
  }

  private static mapToUpdatedState(props: {
    profile: Profile;
    exchangeAccesses?: ExchangeAccess[];
  }): ProfileState {
    const { profile, exchangeAccesses } = props;

    return {
      kind: 'UpdatedProfileState',
      profile,
      exchangeAccesses,
    };
  }

  private static handleError(error: DataError): ProfileState {
    const ERROR: ProfileState = {
      kind: 'ErrorProfileState',
      error: 'Sorry, an error has ocurred. Please try again later',
    };

    switch (error.kind) {
      case 'ApiError':
        break;
      case 'UnexpectedError':
        break;
      case 'Unauthorized':
        break;
      case 'NotFound':
        break;
      default:
        break;
    }

    return ERROR;
  }
}
