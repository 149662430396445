import { Flex, RouterLinkArrow, Text } from '@robotrader/design-system';

import Colors from '@/app/styles/Colors';

const NotFoundPage = () => (
  <Flex.Container
    flexDirection="column"
    justifyContent="center"
    alignContent="center"
    style={{
      backgroundColor: Colors.black,
      color: 'white',
      textAlign: 'center',
      height: '100%',
    }}
  >
    <h1 style={{ fontSize: '15rem', margin: 0 }}>404</h1>
    <h2 style={{ fontSize: '2rem', margin: 0 }}>
      we are sorry, but the page you requested was not found
    </h2>
    <Flex.Container alignItems="center" justifyContent="center" style={{ marginTop: '1rem' }}>
      <RouterLinkArrow to="/" arrowPosition="left">
        <Text.Span fontWeight={700} fontSize={20}>
          Go Home
        </Text.Span>
      </RouterLinkArrow>
    </Flex.Container>
  </Flex.Container>
);

export default NotFoundPage;
