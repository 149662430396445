import { Exchange, OperationDTO, Pair } from '@robotrader/common-types';
import { dayjs } from '@robotrader/common-utils';

import { Operation, TradingSignal } from '@/modules/trader/domain';
import { User } from '@/modules/user/domain';

export class OperationMapper {
  static operationDtoArrayToOperationArray(
    operationsDto: Array<OperationDTO>,
    calcAccum = false,
  ): Operation[] {
    let opsDto = [...operationsDto];
    opsDto = opsDto.sort((op1, op2) =>
      dayjs(op1.createdAt).isAfter(dayjs(op2.createdAt)) ? 1 : -1,
    );

    let accum = 0;

    const operations: Operation[] = opsDto
      .map((opDto: OperationDTO) => {
        if (calcAccum) {
          // if (opDto.fee !== undefined) {
          //   accum -= opDto.fee;
          // }

          if (opDto.outcome !== undefined) {
            accum += opDto.outcome;
          }
        }

        return OperationMapper.operationDtoToOperation(opDto, accum);
      })
      .reverse();

    return operations;
  }

  static operationDtoToOperation(
    opDto: OperationDTO,
    accum: number,
  ): Operation {
    return Operation.create({
      id: opDto.id,
      exchangeOrderId: opDto.exchangeOrderId,
      user: User.create(opDto.user),
      tradingSignal: opDto.tradingSignal
        ? TradingSignal.create({ ...opDto.tradingSignal, accum: 0 })
        : undefined,
      accum,
      exchange: Exchange.create(opDto.exchange),
      action: opDto.action,
      pair: Pair.create(opDto.pair),
      price: opDto.price,
      leverage: opDto.leverage,
      side: opDto.side,
      type: opDto.type,
      status: opDto.status,
      quantity: opDto.quantity,
      createdAt: opDto.createdAt,
      outcome: opDto.outcome ? opDto.outcome : undefined,
      fee: opDto.fee,
      closingOperation: opDto.closingOperation,
    });
  }
}
