import { createRef, KeyboardEvent, useEffect, useState } from 'react';

import { Email, Password } from '@robotrader/common-types';
import {
  Block,
  Button,
  ButtonLoading,
  Card,
  Icon,
  Input,
  Modal,
  Text,
} from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useBlocState, useConfig } from '@/app/hooks';
import Colors from '@/app/styles/Colors';
import { Cradle } from '@/di/Cradle';
import { AuthState } from '@/modules/auth';

interface LoginFormProps {
  submit: (username: Email, password: Password) => void;
  termsAndConditionsClicked: () => void;
  loading?: boolean;
}

const LoginForm = (props: LoginFormProps) => {
  const config = useConfig();
  const { submit, termsAndConditionsClicked, loading } = props;
  const usernameRef = createRef<HTMLInputElement>();
  const passwordRef = createRef<HTMLInputElement>();
  const termsAndConditionsRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (!usernameRef.current || !passwordRef.current) {
      return;
    }

    usernameRef.current.value = config.development ? 'rtarre@nakima.es' : '';
    passwordRef.current.value = config.development ? '123qweQWE' : '';
  }, []);

  const onSubmit = () => {
    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;

    if (username && password && termsAndConditionsRef.current?.checked) {
      submit(username, password);
    } else {
      toast.error('You must provide all the fields and accept the Terms and Conditions');
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !loading) {
      onSubmit();
    }
  };

  return (
    <>
      <Input.Text
        style={{ width: '100%', marginBottom: '2rem' }}
        ref={usernameRef}
        placeholder="Username"
        onKeyDown={handleKeyDown}
      />
      <Input.Password
        style={{ width: '100%', marginBottom: '2rem' }}
        ref={passwordRef}
        placeholder="Password"
        onKeyDown={handleKeyDown}
      />
      <Text.Label
        style={{
          width: '100%',
          marginBottom: '2rem',
          display: 'block',
          textAlign: 'justify',
        }}
      >
        <Input.Checkbox ref={termsAndConditionsRef} />
        <Text.Span style={{ marginLeft: '0.5rem' }}>Acepto los</Text.Span>
        <Button
          $transparent
          $underline
          style={{ marginLeft: '0.5rem' }}
          onClick={termsAndConditionsClicked}
        >
          <Text.Span fontWeight={600}>Términos y condiciones</Text.Span>
        </Button>
      </Text.Label>
      <Block>
        <ButtonLoading $size="md" style={{ width: '100%' }} loading={loading} onClick={onSubmit}>
          <Text.Span fontWeight={600}>Entrar</Text.Span>
        </ButtonLoading>
      </Block>
    </>
  );
};

const LoginPage = () => {
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState<AuthState>(authBloc);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const login = (username: Email, password: Password) => {
    authBloc.logIn(username, password);
  };

  return (
    <>
      <Card.Container borderSize={0} style={{ backgroundColor: Colors.notSoBlack, width: '450px' }}>
        <Card.Body style={{ textAlign: 'center', padding: '4rem' }}>
          <Icon.Logo width={56} height="auto" style={{ marginBottom: '1rem', marginTop: '1rem' }} />
          <Text.H2 fontSize={32} fontWeight={600} style={{ marginBottom: '4rem' }}>
            ROBOTRADER
          </Text.H2>
          <LoginForm
            submit={login}
            termsAndConditionsClicked={() => setIsModalOpen(true)}
            loading={authState.kind === 'LoadingAuthState'}
          />
        </Card.Body>
      </Card.Container>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        size="md"
        position="center"
        title="Términos y condiciones"
      >
        <Text.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu at purus ullamcorper eget.
          Id vestibulum, nec quisque morbi accumsan. Viverra porttitor fermentum tempus ultricies
          mattis quisque aliquet purus fringilla. Commodo accumsan purus felis diam aliquam.
        </Text.Paragraph>
        <Text.Paragraph>
          Ipsum sed fames amet, urna cursus lectus vulputate. Nisl fermentum est gravida eget id
          velit proin congue. Dui lectus at orci, felis. In quis facilisis eros gravida. Nec aliquam
          non eget dignissim. Arcu arcu, platea ipsum, nam risus. Eleifend in habitasse risus
          ridiculus commodo. Id ante in sagittis tellus neque, sit. Cursus ac in hendrerit ut
          pellentesque nulla scelerisque.
        </Text.Paragraph>
        <Block style={{ textAlign: 'center', marginTop: '4rem' }}>
          <Button
            $size="md"
            style={{ width: 'max(50%, 200px)' }}
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Aceptar
          </Button>
        </Block>
      </Modal>
    </>
  );
};

export default LoginPage;
