export const truncateString = (
  fullString: string,
  strLen: number,
  separator = '...',
): string => {
  if (!fullString) return '';

  if (fullString.length <= strLen) return fullString;

  const sepLen = separator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullString.substr(0, frontChars) +
    separator +
    fullString.substr(fullString.length - backChars)
  );
};

export const hexToNumber = (hex: string): number => Number.parseInt(hex, 16);

export const roundNumber = (num: number, decimals: number = 2): number => {
  const base = 10 ** decimals;

  return Math.round((num + Number.EPSILON) * base) / base;
};
