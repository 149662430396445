import {
  asClass,
  createContainer,
  InjectionMode,
  // asValue,
  // asFunction,
} from 'awilix';

import { Cradle } from '@/di/Cradle';
import {
  AuthBloc,
  AuthHTTPRepository,
  GetAuthUseCase,
  LoginUseCase,
  LogoutUseCase,
} from '@/modules/auth';
import {
  AddExchangeAccessesUseCase,
  GetProfileUseCase,
  ProfileBloc,
  ProfileHTTPRepository,
  RemoveExchangeAccessesUseCase,
} from '@/modules/profile';
import { HTTPService, LocalStorageRepository } from '@/modules/shared';
import {
  ExchangeHTTPRepository,
  GetAllExchangesUseCase,
  GetAllOperationsUseCase,
  GetTraderRunningStateUseCase,
  // OperationMockedRepository,
  OperationHTTPRepository,
  SetTraderStateUseCase,
  TraderBloc,
} from '@/modules/trader';

// Create the container
export const container = createContainer<Cradle>({
  injectionMode: InjectionMode.PROXY,
});

// There are 3 lifetime types available.

// Lifetime.TRANSIENT: This is the default. The registration is resolved every time it is needed. This means if you resolve a class more than once, you will get back a new instance every time.
// Lifetime.SCOPED: The registration is scoped to the container - that means that the resolved value will be reused when resolved from the same scope (or a child scope).
// Lifetime.SINGLETON: The registration is always reused no matter what - that means that the resolved value is cached in the root container.

// Inject the dependencies
container.register({
  // config
  // config must be registered at the APP Startup since it is environment dependant
  // BLoCs
  authBloc: asClass(AuthBloc).singleton(),
  profileBloc: asClass(ProfileBloc).singleton(),
  traderBloc: asClass(TraderBloc).singleton(),
  // Services
  httpService: asClass(HTTPService).singleton(),
  // Repos
  authRepository: asClass(AuthHTTPRepository).singleton(),
  profileRepository: asClass(ProfileHTTPRepository).singleton(),
  operationRepository: asClass(OperationHTTPRepository).singleton(),
  exchangeRepository: asClass(ExchangeHTTPRepository).singleton(),
  storageRepository: asClass(LocalStorageRepository).singleton(),
  // useCases
  loginUseCase: asClass(LoginUseCase).transient(),
  logoutUseCase: asClass(LogoutUseCase).transient(),
  getAuthUseCase: asClass(GetAuthUseCase).transient(),
  getProfileUseCase: asClass(GetProfileUseCase).transient(),
  addExchangeAccessesUseCase: asClass(AddExchangeAccessesUseCase).transient(),
  removeExchangeAccessesUseCase: asClass(
    RemoveExchangeAccessesUseCase,
  ).transient(),
  getAllOperationsUseCase: asClass(GetAllOperationsUseCase).transient(),
  getAllExchangesUseCase: asClass(GetAllExchangesUseCase).transient(),
  setTraderStateUseCase: asClass(SetTraderStateUseCase).transient(),
  getTraderRunningStateUseCase: asClass(
    GetTraderRunningStateUseCase,
  ).transient(),
});
