import { ChangeEvent, createRef, useEffect, useState } from 'react';

import { Exchange, ExchangeName } from '@robotrader/common-types';
import {
  Block,
  Button,
  ButtonLoading,
  Flex,
  Icon,
  Input,
  LabelValue,
  LoadingBlock,
  Modal,
  Text,
} from '@robotrader/design-system';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { useCradle } from '@/app/contexts';
import { useBlocState, useIsMounted } from '@/app/hooks';
import { ExchangeList } from '@/app/ui';
import { Cradle } from '@/di/Cradle';
import { ExchangeAccess } from '@/modules/shared/domain';

const NoExchangeConnected = () => (
  <Block $inline style={{ textAlign: 'center' }}>
    <Icon.Box height={80} />
    <Text.Paragraph style={{ marginBottom: '3rem' }}>
      No estás conectado con ningún exchange
    </Text.Paragraph>
  </Block>
);

interface AddExchangeFormProps {
  submit: (
    exchangeName: ExchangeName,
    name: string,
    apiKey: string,
    token: string,
    secret: string,
  ) => void;
}

const AddExchangeForm = (props: AddExchangeFormProps) => {
  const { submit } = props;
  const { authBloc, traderBloc } = useCradle<Cradle>();
  const isMounted = useIsMounted();
  const authState = useBlocState(authBloc);
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [exchangeName, setExchangeName] = useState<ExchangeName | undefined>('asd');
  const exchangeAccessNameRef = createRef<HTMLInputElement>();
  const apiKeyRef = createRef<HTMLInputElement>();
  const tokenRef = createRef<HTMLInputElement>();
  const secretRef = createRef<HTMLInputElement>();

  const handleOnSubmit = () => {
    const exchangeAccessName = exchangeAccessNameRef.current?.value;
    const apiKey = apiKeyRef.current?.value;
    const token = tokenRef.current?.value;
    const secret = secretRef.current?.value;

    if (exchangeName && exchangeAccessName && apiKey && token && secret) {
      submit(exchangeName, exchangeAccessName, apiKey, token, secret);
    } else {
      toast.error('You must provide all the fields');
    }
  };

  useEffect(() => {
    if (isMounted()) {
      traderBloc.getExchanges().then(setExchanges);
    }
  }, [isMounted]);

  return (
    <>
      <Block style={{ marginBottom: '1rem' }} $inline>
        <Text.Strong style={{ marginRight: '1rem' }}>Exchange:</Text.Strong>
        <Input.Select
          defaultValue=""
          style={{ width: 'auto' }}
          onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
            const { value } = evt.target;
            setExchangeName(value !== '' ? value : undefined);
          }}
        >
          <option value="" disabled>
            Seleccionar Exchange
          </option>
          {exchanges &&
            exchanges.map((e) => (
              <option key={e.id} value={e.name}>
                {e.name}
              </option>
            ))}
        </Input.Select>
      </Block>
      <Input.Text
        style={{ width: '100%', marginBottom: '2rem' }}
        ref={exchangeAccessNameRef}
        placeholder="Nombre de la Clave"
      />
      <Input.Text
        style={{ width: '100%', marginBottom: '2rem' }}
        ref={apiKeyRef}
        placeholder="Clave API"
      />
      <Input.Text
        style={{ width: '100%', marginBottom: '2rem' }}
        ref={tokenRef}
        placeholder="Token o contraseña"
      />
      <Input.Text
        style={{ width: '100%', marginBottom: '2rem' }}
        ref={secretRef}
        placeholder="Secret"
      />
      <Block>
        <ButtonLoading
          $size="md"
          style={{ width: '100%' }}
          loading={authState.kind === 'LoadingAuthState'}
          onClick={handleOnSubmit}
        >
          <Text.Span fontWeight={600}>Añadir exchange</Text.Span>
        </ButtonLoading>
      </Block>
    </>
  );
};

const ProfilePage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const { authBloc, profileBloc, traderBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const profileState = useBlocState(profileBloc);
  const traderState = useBlocState(traderBloc);

  useEffect(() => {
    if (isMounted()) {
      profileBloc.loadProfile();
    }
  }, [isMounted]);

  const addExchange = async (
    exchangeName: ExchangeName,
    name: string,
    apiKey: string,
    token: string,
    secret: string,
  ) => {
    const newExchangeAccess = { id: uuidv4(), name, apiKey, token, secret, exchangeName };

    try {
      await profileBloc.addExchangeAccess(newExchangeAccess);
      setIsModalOpen(false);
    } catch (error: any) {
      if ('error' in error) {
        toast.error(error.error);
      } else if ('message' in error) {
        toast.error(error.message);
      } else {
        toast.error(error);
      }
    }
  };

  const removeExchange = async (exchangeAccess: ExchangeAccess) => {
    if (traderState.running) {
      toast.error('Exchange deletion is not allowed while trader is running');
      return;
    }
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `Estás seguro que quieres borrar '${exchangeAccess.name}' de '${exchangeAccess.exchangeName}'?`,
    );

    if (!confirm) return;

    try {
      await profileBloc.removeExchangeAccess(exchangeAccess.id);
    } catch (error: any) {
      if ('error' in error) {
        toast.error(error.error);
      } else if ('message' in error) {
        toast.error(error.message);
      } else {
        toast.error(error);
      }
    }
  };

  if (
    authState.kind !== 'LoadedAuthState' ||
    (profileState.kind !== 'LoadedProfileState' && profileState.kind !== 'UpdatedProfileState')
  ) {
    return (
      <LoadingBlock
        loading={
          authState.kind === 'LoadingAuthState' || profileState.kind === 'LoadingProfileState'
        }
        text={profileState.kind === 'ErrorProfileState' ? 'Your profile could not be loaded' : ''}
      />
    );
  }

  return (
    <>
      <Text.Title2 style={{ marginBottom: '3rem' }}>Perfil</Text.Title2>
      <Flex.Container alignItems="center">
        <LabelValue
          label="Name"
          value={`${profileState.profile.name} ${profileState.profile.surname}`}
        />
        <LabelValue
          label="Username"
          value={authState.auth.username}
          style={{ marginLeft: '2rem' }}
        />
        <LabelValue style={{ marginLeft: '2rem' }} label="ID usuario" value={authState.auth.id} />
        <Button
          $size="md"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            if (traderState.running) {
              toast.error('It is not allowed to add an exchange while trader is running');
            } else {
              setIsModalOpen(true);
            }
          }}
        >
          <Icon.Plus width={15} />
          <Text.Span style={{ marginLeft: '0.5rem' }} fontWeight={600}>
            Añadir nuevo exchange
          </Text.Span>
        </Button>
      </Flex.Container>
      {!profileState.exchangeAccesses && (
        <Flex.Container style={{ flex: '1 1 auto' }} justifyContent="center" alignItems="center">
          <NoExchangeConnected />
        </Flex.Container>
      )}
      {profileState.exchangeAccesses && (
        <ExchangeList
          style={{ marginTop: '3rem' }}
          exchanges={profileState.exchangeAccesses}
          removeExchange={removeExchange}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        closeable
        size="sm"
        position="center"
        title="Añadir nueva cuenta de exchange"
      >
        <Block style={{ padding: '3rem' }}>
          <AddExchangeForm submit={addExchange} />
        </Block>
      </Modal>
    </>
  );
};

export default ProfilePage;
