import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { ProfileRepository } from '@/modules/profile/domain';

interface ToggleTraderProps {
  isTrading: boolean;
}

interface SetTraderStateProps {
  profileRepository: ProfileRepository;
}

export class SetTraderStateUseCase
  implements UseCase<ToggleTraderProps, Either<DataError, void>>
{
  private profileRepository: ProfileRepository;

  constructor({ profileRepository }: SetTraderStateProps) {
    this.profileRepository = profileRepository;
  }

  async execute(props: ToggleTraderProps): Promise<Either<DataError, void>> {
    const { isTrading } = props;

    const response = await this.profileRepository.changeTrading(isTrading);

    return response.fold(
      (error) => Either.left(error),
      () => Either.right(undefined as void),
    );
  }
}
