import { ExchangeAccessId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { ProfileRepository } from '@/modules/profile/domain';
import { ExchangeAccess } from '@/modules/shared';

interface RemoveExchangeAccessesUseCaseProps {
  profileRepository: ProfileRepository;
}

export class RemoveExchangeAccessesUseCase
  implements UseCase<ExchangeAccessId, Either<DataError, ExchangeAccess[]>>
{
  private profileRepository: ProfileRepository;

  constructor({ profileRepository }: RemoveExchangeAccessesUseCaseProps) {
    this.profileRepository = profileRepository;
  }

  execute(
    exchangeAccessId: ExchangeAccessId,
  ): Promise<Either<DataError, ExchangeAccess[]>> {
    return this.profileRepository.removeExchangeAccess(exchangeAccessId);
  }
}
