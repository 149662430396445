import { ExchangeAccessId, JobI } from '@robotrader/common-types';
import { DataError, Either, isApiError } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import {
  AddExchangeAccessProps,
  Profile,
  ProfileRepository,
} from '@/modules/profile/domain';
import { ExchangeAccess, HTTPService } from '@/modules/shared';
import {
  ExchangeAccessesResponse,
  GetUserResponse,
} from '@/modules/user/infra';

import { ProfileMapper } from './ProfileMapper';
import { TradingResponse } from './TradingResponse';

interface ProfileHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class ProfileHTTPRepository implements ProfileRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: ProfileHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getProfile(): Promise<Either<DataError, Profile>> {
    try {
      const {
        data: { data },
      } = await this.http.get<GetUserResponse>(`${this.config.apiUrl}/user/me`);

      return Either.right(ProfileMapper.profileDtoToProfile(data.profile));
    } catch (error) {
      return Either.left(ProfileHTTPRepository.HandleError(error));
    }
  }

  async getExchangeAccesses(): Promise<Either<DataError, ExchangeAccess[]>> {
    try {
      const {
        data: { data },
      } = await this.http.get<ExchangeAccessesResponse>(
        `${this.config.apiUrl}/client/user/me/exchange-accesses`,
      );

      return Either.right(data);
    } catch (error) {
      return Either.left(ProfileHTTPRepository.HandleError(error));
    }
  }

  async addExchangeAccess(
    exchangeAccess: AddExchangeAccessProps,
  ): Promise<Either<DataError, ExchangeAccess[]>> {
    try {
      const {
        data: { data: newExchanges },
      } = await this.http.post<ExchangeAccessesResponse>(
        `${this.config.apiUrl}/client/user/me/exchange-access`,
        { exchangeAccess },
      );

      return Either.right(newExchanges);
    } catch (error) {
      return Either.left(ProfileHTTPRepository.HandleError(error));
    }
  }

  async removeExchangeAccess(
    exchangeAccessId: ExchangeAccessId,
  ): Promise<Either<DataError, ExchangeAccess[]>> {
    try {
      const {
        data: { data: newExchanges },
      } = await this.http.delete<ExchangeAccessesResponse>(
        `${this.config.apiUrl}/client/user/me/exchange-access/${exchangeAccessId}`,
      );

      return Either.right(newExchanges);
    } catch (error) {
      return Either.left(ProfileHTTPRepository.HandleError(error));
    }
  }

  async isTrading(): Promise<Either<DataError, boolean>> {
    try {
      const {
        data: { data },
      } = await this.http.get<GetUserResponse>(`${this.config.apiUrl}/user/me`);

      return Either.right(data.profile.isTrading);
    } catch (error) {
      return Either.left(ProfileHTTPRepository.HandleError(error));
    }
  }

  async changeTrading(
    isTrading: boolean,
  ): Promise<Either<DataError, JobI<boolean>>> {
    try {
      const {
        data: { data },
      } = await this.http.patch<TradingResponse>(
        `${this.config.apiUrl}/client/user/me/trading`,
        { isTrading },
      );

      return Either.right(data);
    } catch (error) {
      return Either.left(ProfileHTTPRepository.HandleError(error));
    }
  }

  private static HandleError(error: unknown): DataError {
    if (isApiError(error)) {
      return { kind: 'ApiError', ...error.data };
    }

    return { kind: 'UnexpectedError', message: error as Error };
  }
}
