import { ProfileDTO } from '@robotrader/common-types';

import { Profile } from '@/modules/profile/domain';

export class ProfileMapper {
  static profileDtoArrayToProfileArray(
    profilesDto: Array<ProfileDTO>,
  ): Profile[] {
    return profilesDto.map(ProfileMapper.profileDtoToProfile);
  }

  static profileDtoToProfile(profileDto: ProfileDTO): Profile {
    return Profile.create(profileDto);
  }
}
