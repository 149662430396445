import { useState } from 'react';

import {
  Button,
  Dropdown,
  Flex,
  Icon,
  Modal,
  Nav,
  NavLogo,
  RouterLink,
  Text,
} from '@robotrader/design-system';
import { toast } from 'react-toastify';

import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';
import Colors from '@/app/styles/Colors';
import { Cradle } from '@/di/Cradle';

const NavBar = () => {
  const { authBloc, traderBloc, profileBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const traderState = useBlocState(traderBloc);
  const profileState = useBlocState(profileBloc);
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState<boolean>(false);

  const startTrader = async () => {
    await traderBloc.startTrader();

    setTimeout(() => {
      traderBloc.loadTradingState();
    }, 1500);

    setIsDisclaimerModalOpen(false);
  };

  const stopTrader = async () => {
    await traderBloc.stopTrader();
    setTimeout(() => {
      traderBloc.loadTradingState();
    }, 1500);
    setIsDisclaimerModalOpen(false);
  };

  if (authState.kind !== 'LoadedAuthState') {
    return null;
  }

  return (
    <>
      <Nav style={{ justifyContent: 'space-between', height: '70px' }}>
        <Flex.Container>
          <NavLogo style={{ height: '45px', marginRight: '3rem' }} to="/" />
        </Flex.Container>

        <Flex.Container alignItems="center" style={{ gap: '4rem' }}>
          <Button
            $size="sm"
            $outline
            $outlineSize={2}
            $outlineColor={Colors.transparent}
            $outlineColorHover={Colors.grey2}
            $backgroundColor={Colors.grey2}
            $backgroundColorHover={Colors.transparent}
            onClick={() => {
              if (
                traderState.running === false &&
                profileState.kind === 'LoadedProfileState' &&
                (profileState.exchangeAccesses === undefined ||
                  profileState.exchangeAccesses.length === 0)
              ) {
                toast.error('There are no exchanges added to your profile');
              } else {
                setIsDisclaimerModalOpen(true);
              }
            }}
          >
            <Flex.Container alignItems="center" style={{ width: '110px' }}>
              {traderState.running === false && (
                <>
                  <Icon.Play width={40} />
                  <Text.Span style={{ marginLeft: '1.5rem' }}>Play</Text.Span>
                </>
              )}
              {traderState.running === true && (
                <>
                  <Icon.Pause width={40} />
                  <Text.Span style={{ marginLeft: '1.5rem' }}>Stop</Text.Span>
                </>
              )}
            </Flex.Container>
          </Button>
          <Dropdown.Container>
            <Dropdown.Button>
              <Icon.User width={21} />
            </Dropdown.Button>
            <Dropdown.Content>
              <Flex.Container flexDirection="column">
                <RouterLink
                  to="/profile"
                  $backgroundColorHover={Colors.grey}
                  $transparent
                  $size="md"
                  $full
                  style={{ justifyContent: 'flex-start' }}
                >
                  Profile
                </RouterLink>
                <Button
                  $backgroundColorHover={Colors.grey}
                  $transparent
                  $size="md"
                  $full
                  style={{ justifyContent: 'space-between' }}
                  onClick={() => {
                    authBloc.logOut();
                  }}
                >
                  Logout <Icon.LogOut width={21} />
                </Button>
              </Flex.Container>
            </Dropdown.Content>
          </Dropdown.Container>
        </Flex.Container>
      </Nav>
      <Modal isOpen={isDisclaimerModalOpen} title="Disclaimer" size="sm" position="top">
        {traderState.running === false && (
          <>
            <Text.Paragraph>
              Al pulsar el botón "Aceptar" entiendes que empezarás una inversión automática y que
              toda inversión tiene riesgo, incluso cabe la posibilidad de perderlo todo.
            </Text.Paragraph>
            <Text.Paragraph>
              Cuando la operación se haya realizado recibirás un correo electrónico de confirmación.
            </Text.Paragraph>
            <Flex.Container style={{ width: '80%', margin: '3rem auto 0 auto', gap: '4rem' }}>
              <Button
                $size="md"
                $variant="cancel"
                style={{ width: 'max(50%, 200px)' }}
                onClick={() => {
                  setIsDisclaimerModalOpen(false);
                }}
              >
                Cancelar
              </Button>
              <Button $size="md" style={{ width: 'max(50%, 200px)' }} onClick={startTrader}>
                Aceptar
              </Button>
            </Flex.Container>
          </>
        )}
        {traderState.running === true && (
          <>
            <Text.Paragraph>
              El stop no es instantaneo. En caso de que existan, se cancelarán todas las posiciones
              activas para que sea efectivo.
            </Text.Paragraph>
            <Text.Paragraph>¿Estás seguro que deseas ejecutar esta acción?</Text.Paragraph>
            <Text.Paragraph>
              Cuando la operación se haya realizado recibirás un correo electrónico de confirmación.
            </Text.Paragraph>
            <Flex.Container style={{ width: '80%', margin: '3rem auto 0 auto', gap: '4rem' }}>
              <Button
                $size="md"
                $variant="cancel"
                style={{ width: 'max(50%, 200px)' }}
                onClick={() => {
                  setIsDisclaimerModalOpen(false);
                }}
              >
                Cancelar
              </Button>
              <Button $size="md" style={{ width: 'max(50%, 200px)' }} onClick={stopTrader}>
                Aceptar
              </Button>
            </Flex.Container>
          </>
        )}
      </Modal>
    </>
  );
};

export default NavBar;
