import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { Profile, ProfileRepository } from '@/modules/profile/domain';

interface GetProfileUseCaseProps {
  profileRepository: ProfileRepository;
}

export class GetProfileUseCase
  implements UseCase<void, Either<DataError, Profile>>
{
  private profileRepository: ProfileRepository;

  constructor({ profileRepository }: GetProfileUseCaseProps) {
    this.profileRepository = profileRepository;
  }

  execute(): Promise<Either<DataError, Profile>> {
    return this.profileRepository.getProfile();
  }
}
