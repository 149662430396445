import { ChangeEvent, useEffect, useState } from 'react';

import { Action, Exchange, ExchangeId } from '@robotrader/common-types';
import { stringToNumber } from '@robotrader/common-utils';
import {
  Block,
  Card,
  DynamicTable,
  EvolutionGraph,
  Flex,
  Input,
  LabelValue,
  LoadingBlock,
  OperationEntryExitAmount,
  OperationEntryExitDates,
  OperationEntryExitFee,
  OperationEntryExitPrice,
  OperationExchangeIcon,
  OperationOutcomeAndPercentage,
  TableObjectProperties,
  Tabs,
  Text,
  UpdateTimeInfo,
} from '@robotrader/design-system';

import { useCradle } from '@/app/contexts';
import { useBlocState, useInterval, useIsMounted, useTabs } from '@/app/hooks';
import Colors from '@/app/styles/Colors';
import { roundNumber } from '@/app/utils';
import { Cradle } from '@/di/Cradle';
import { Operation } from '@/modules/trader';

const DELAY_TIME = 15000; // milliseconds
const TABS = {
  TRADING_HISTORY: 'Trading History',
  OPEN_POSITIONS: 'Open positions',
};

const OPERATIONS_PROPS: Array<TableObjectProperties<Operation>> = [
  { label: 'Date', data: OperationEntryExitDates },
  { label: 'Exchange', data: OperationExchangeIcon, align: 'center' },
  { label: 'Symbol', data: (o: Operation) => o.pair.name, align: 'center' },
  { label: 'Direction', data: (o: Operation) => `${o.direction}`, align: 'center' },
  { label: 'Quantity', data: (o: Operation) => `${o.quantity}`, align: 'center' },
  { label: 'Price', data: OperationEntryExitPrice },
  { label: 'Amount', data: OperationEntryExitAmount },
  { label: 'Fee', data: OperationEntryExitFee },
  { label: 'Outcome', data: OperationOutcomeAndPercentage },
  {
    label: 'Accum',
    data: (o: Operation) => (o.accum ? `${roundNumber(o.accum, 4)}` : undefined),
    color: (o: Operation) => {
      if (o.accum === undefined) return undefined;

      return o.accum >= 0 ? Colors.green : Colors.red;
    },
  },
];

const HomePage = () => {
  const { traderBloc, authBloc } = useCradle<Cradle>();
  const traderState = useBlocState(traderBloc);
  const authState = useBlocState(authBloc);
  const isMounted = useIsMounted();
  const { currentTab, setCurrentTab, checkCurrentTabIs, tabs } = useTabs(Object.values(TABS));
  const [loadOperations, setLoadOperations] = useState<boolean>(true);
  const [exchanges, setExchanges] = useState<Exchange[] | undefined>(undefined);
  const [exchangeId, setExchangeId] = useState<ExchangeId | undefined>(undefined);

  useEffect(() => {
    traderBloc.getExchanges().then(setExchanges);
  }, []);

  useEffect(() => {
    if (!loadOperations) return;

    traderBloc.loadOperations(exchangeId).finally(() => setLoadOperations(false));
  }, [loadOperations]);

  useInterval(
    () => {
      if (authState.kind !== 'LoadedAuthState') return;

      setLoadOperations(true);
    },
    // Delay in milliseconds or null to stop it
    isMounted() ? DELAY_TIME : null,
  );

  useEffect(() => {
    if (authState.kind !== 'LoadedAuthState') return;

    setLoadOperations(true);
  }, [exchangeId]);

  if (traderState.kind !== 'LoadedTraderState') {
    return <LoadingBlock loading text="Loading trader state..." />;
  }

  return (
    <>
      <UpdateTimeInfo milliseconds={DELAY_TIME} textColor={Colors.grey5} loading={loadOperations} />
      <Card.Container style={{ marginTop: '1rem' }}>
        <Card.Body>
          <Flex.Container alignItems="center">
            <Block $inline style={{ marginRight: '1rem' }}>
              <Text.Strong style={{ marginRight: '1rem' }}>Exchange:</Text.Strong>
              <Input.Select
                defaultValue={undefined}
                style={{ width: 'auto' }}
                onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                  const { value } = evt.target;
                  setExchangeId(value !== '' ? stringToNumber(value) : undefined);
                }}
              >
                <option value="">All</option>
                {exchanges &&
                  exchanges.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.name}
                    </option>
                  ))}
              </Input.Select>
            </Block>
            <LabelValue
              label="Acumulado"
              value={`${roundNumber(traderState.accum, 3)} USDT`}
              valueColor={traderState.accum >= 0 ? Colors.green : Colors.red}
            />
            <Text.Span
              fontWeight={700}
              fontSize={18}
              style={{
                marginLeft: 'auto',
                color: traderState.running === true ? Colors.green : Colors.red,
              }}
            >
              {traderState.running === true ? 'Conectado' : 'Desconectado'}
            </Text.Span>
          </Flex.Container>
        </Card.Body>
      </Card.Container>
      <EvolutionGraph
        style={{
          flex: 1,
          marginTop: '1rem',
          minHeight: '20vh',
          maxHeight: '25vh',
        }}
        operations={traderState.operations.filter((op) => op.action === Action.EXIT)}
        lineColor={traderState.accum >= 0 ? Colors.green : Colors.red}
      />
      <Tabs.Container
        style={{ flex: 1, marginTop: '1rem' }}
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      >
        {checkCurrentTabIs(TABS.TRADING_HISTORY) && (
          <Tabs.Item style={{ height: 'max(45vh, 200px)', overflow: 'auto', paddingTop: '1rem' }}>
            <DynamicTable
              elements={traderState.operations.filter((op) => op.action === Action.ENTRY)}
              properties={OPERATIONS_PROPS}
              uniqueKeyName="id"
              // showIdColumn
            />
          </Tabs.Item>
        )}
        {checkCurrentTabIs(TABS.OPEN_POSITIONS) && (
          <Tabs.Item style={{ height: 'max(30vh, 200px)', overflow: 'auto' }}>
            <DynamicTable
              elements={traderState.operations.filter((op) => op.isOpen)}
              properties={OPERATIONS_PROPS}
              uniqueKeyName="id"
              showIdColumn
            />
          </Tabs.Item>
        )}
      </Tabs.Container>
    </>
  );
};

export default HomePage;
