import { ExchangeId, OperationId } from '@robotrader/common-types';
import { DataError, Either, UseCase } from '@robotrader/core-lib';

import { Operation, OperationRepository } from '@/modules/trader/domain';

interface GetAllOperationsUseCaseProps {
  operationRepository: OperationRepository;
}

export class GetAllOperationsUseCase
  implements UseCase<ExchangeId | undefined, Either<DataError, Operation[]>>
{
  private operationRepository: OperationRepository;

  constructor({ operationRepository }: GetAllOperationsUseCaseProps) {
    this.operationRepository = operationRepository;
  }

  async execute(
    exchangeId?: ExchangeId,
  ): Promise<Either<DataError, Operation[]>> {
    const allOperationsResult = await this.operationRepository.getAll(
      exchangeId,
    );

    const allOperations = allOperationsResult.getOrThrow();

    const opsMap = allOperations.reduce((map, op) => {
      map.set(op.id, op);
      return map;
    }, new Map<OperationId, Operation>());

    const allOperationsAccum = allOperations.map((op) => {
      const { closingOperation } = op;

      if (closingOperation === undefined) return op;

      const foundOperation = opsMap.get(closingOperation.id);

      if (!foundOperation) return op;

      // eslint-disable-next-line no-param-reassign
      op.accum = foundOperation.accum;
      return op;
    });

    return Either.right(allOperationsAccum);
  }
}
