import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { AuthLayout, MainLayout } from '@/app/ui/layouts';

import HomePage from './HomePage/HomePage';
import LoginPage from './LoginPage/LoginPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import ProfilePage from './ProfilePage/ProfilePage';
import { AuthRoute, NotAuthRoute } from './ProtectedRoutes';

const routes: RouteObject[] = [
  {
    children: [
      {
        element: (
          <AuthRoute>
            <MainLayout />
          </AuthRoute>
        ),
        children: [
          { index: true, element: <HomePage /> },
          { path: 'profile', element: <ProfilePage /> },
        ],
      },
      {
        path: '/auth',
        element: <AuthLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/auth/login" replace />,
          },
          {
            path: 'login',
            element: (
              <NotAuthRoute>
                <LoginPage />
              </NotAuthRoute>
            ),
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

const Navigator = () => {
  const Router = useRoutes(routes);

  return Router;
};

export default Navigator;
